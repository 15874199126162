/**
 * Created by jerry on 2020/03/5.
 * 通用次卡分类api
 */
import * as API from '@/api/index'

export default {
    // 通用次卡分类列表
    getGeneralCardCategory: params => {
        return API.POST('api/generalCardCategory/all', params)
    },
    //新增通用次卡分类
    createGeneralCardCategory: params => {
        return API.POST('api/generalCardCategory/create', params)
    },
    //编辑通用次卡分类
    updateGeneralCardCategory:params => {
        return API.POST('api/generalCardCategory/update', params)
    },
    //通用次卡分类顺序调整
    moveGeneralCardCategory:params => {
        return API.POST('api/generalCardCategory/move', params)
    },

}